<template>
  <div class="about" id="dementie-information">
    <alert messageType="error" :message="errorMessage" @close="errorMessage = null" position="absolute" />
    <div class="parent clearfix">
      <div class="bg-illustration">
        <img :src="icon" alt="logo">
        <div class="burger-btn">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div class="login">
        <div class="container">
          <h1>Reset wachtwoord</h1>
          <p class="info">Hier kunt u uw wachtwoord opnieuw instellen. De minimale lengte van het wachtwoord is 8. </p>
          <div class="login-form" v-on:keyup.enter="setPassword()">
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css"> 
            <div class="form-group mb-4">
              <input v-if="showPassword1" type="text" v-model="user.new_password1" id="username" class="form-control input"
              :class="{ 'is-invalid': $v.user.new_password1.$error}" placeholder="Nieuw wachtwoord" @click="failure=false"/>
              <input v-else type="password" v-model="user.new_password1" id="username" class="form-control input"
              :class="{ 'is-invalid': $v.user.new_password1.$error}" placeholder="Nieuw wachtwoord" @click="failure=false"/>
              <span v-if="showPassword1" class='ab'><i class="fa fa-eye-slash" @click="toggleShow"></i></span> 
              <span v-else class='ab'><i class="fa fa-eye" @click="toggleShow"></i></span> 
              <div v-if="$v.user.new_password1.$error" class="invalid-feedback">
                <span v-if="!$v.user.new_password1.required">Wachtwoord is vereist</span>
              </div>
            </div>
            <div class="form-group mb-4">
              <input v-if="showPassword2" type="text" class="form-control input"  placeholder="Bevestig wachtwoord" v-model="user.new_password2" 
              :class="{ 'is-invalid': $v.user.new_password2.$error }" id="new_password2" @click="failure=false"/>
              <input v-else type="password" class="form-control input" placeholder="Bevestig wachtwoord" v-model="user.new_password2"
              :class="{ 'is-invalid': $v.user.new_password2.$error }" id="new_password2" @click="failure=false">
              <span v-if="showPassword2" class='abb'><i class="fa fa-eye-slash" @click="toggleShow2"></i></span> 
              <span v-else class='abb'><i class="fa fa-eye" @click="toggleShow2"></i></span> 
              <div v-if="$v.user.new_password2.$error" class="invalid-feedback">
                <span v-if="!$v.user.new_password2.required">Wachtwoord is vereist</span>
              </div>
            </div>
          </div>
          <button class="btn btn-secondary" @click="setPassword()" :disabled="success">Instellen</button>
          <p v-if="success === true">Je wachtwoord is aangepast. Ga nu naar de <router-link class="link" to="/login" tag="a">login pagina</router-link> om in te loggen.</p>
          <p v-if="failure === true && failure_type === 'uid'" class="failure">ERROR: De link om je wachtwoord te wijzigen is verlopen. Vraag een nieuwe link aan door naar de <router-link to="/login" tag="a">login pagina</router-link> te gaan en op Wachtwoord vergeten? te klikken.</p>
          <p v-else-if="failure === true && failure_type === 'token'" class="failure">ERROR: Je hebt deze link al eerder gebruikt om je wachtwoord te wijzigen. Wil je hem nog een keer wijzigen, vraag dan een nieuwe link aan door naar de <router-link to="/login" tag="a">login pagina</router-link> te gaan en op Wachtwoord vergeten? te klikken.</p>
          <p v-else-if="failure === true && failure_type === 'passwords_do_not_match'" class="failure">ERROR: De twee wachtwoorden zijn niet hetzelfde.</p>
          <p v-else-if="failure === true && failure_type === 'passwords_too_short'" class="failure">ERROR: Het wachtwoord is te kort. Het moet tenminste 8 tekens hebben.</p>
          <p v-else-if="failure === true" class="failure"> {{failure_type}} </p>
        </div>
        </div>
      </div>
    </div>
</template>

<script>

import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import Alert from '../components/Alert.vue'
import icon from '@/assets/LoginIcon.svg'

export default {
  name: 'ResetPassword',
  components: { Alert },
  data () {
    return {
      icon:icon,
      showPassword1: false,
      showPassword2: false,
      user: {
        uid: null,
        token: null,
        new_password1: null,
        new_password2: null
      },
      success: false,
      failure: false,
      failure_type: null
    }
  },
  validations: {
      user: {
        new_password1: { required },
        new_password2: { required }
      }
  },
  methods: {
    setPassword () {
      this.user.uid = this.$route.params.uid.split("/")[0]
      this.user.token = this.$route.params.uid.split("/")[1]
      this.$v.$touch()
      if (!this.$v.$invalid) {
        axios.post(`${process.env.VUE_APP_API_URL}/rest-auth/password/reset/confirm/`, this.user).then(
            () => {
              this.success = true
            },
            (error) => {
              console.log(error.response.data)
              this.failure = true
              if(Object.keys(error.response.data).includes('uid')){
                this.failure_type = "uid"
              }
              else if(Object.keys(error.response.data).includes('token')){
                this.failure_type = "token"
              }
              else if(Object.keys(error.response.data).includes('new_password2')&&error.response.data.new_password2[0]==='This password is too short. It must contain at least 8 characters.'){
                this.failure_type = "passwords_too_short"
              }
              else if(Object.keys(error.response.data).includes('new_password2')&&error.response.data.new_password2[0]==='The two password fields didn’t match.'){
                this.failure_type = "passwords_do_not_match"
              }
              else if(Object.keys(error.response.data).includes('new_password2')){
                this.failure_type = error.response.data.new_password2[0]
              }
            }
          )
      }
    },
    toggleShow() {
      this.showPassword1 = !this.showPassword1;
    },
    toggleShow2() {
      this.showPassword2 = !this.showPassword2;
    }
  }
}
</script>

<style scoped>

.footer a {
  color: #FFF !important;
}

input, small {
  font-size: 12px;
}

.form-control{
border-bottom:2px rgba(213, 218, 221, 1) solid !important;
border: none;
border-radius: 0px;
padding: 0.1rem;
font-size: 12px;
margin-top: 10px;
}

.form-control:focus{
border-bottom:2px rgba(70, 207, 200, 1) solid !important;
}

.form {
  width: 20%;
}

.title-wrapper {
  margin-bottom: 5vh;
}

.btn-secondary {
  background: var(--color-1);
  font-weight: bold;
  height: 40px;
  padding-top: 5px !important;
  z-index: 12;
  margin-top: 10px;
  width: 150px;
  max-width: 300px;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

body {
  background-color: #fff;
  font-family: Montserrat;
  overflow-x: hidden;
}

article,
aside,
details,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  text-decoration: none;
}

img {
  border: none;
}

*:focus {
  outline: none;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.bg-illustration {
  position: relative;
  height: 100vh;
  width: 1194px;
  background: transparent no-repeat center center scroll;
  background-size: cover;
  float: left;
  -webkit-animation: bgslide 2.3s forwards;
          animation: bgslide 2.3s forwards;
}
.bg-illustration img {
  width: 248px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: auto;
  margin: 100px 0 0 120px;
}

@-webkit-keyframes bgslide {
  from {
    left: -100%;
    width: 0;
    opacity: 0;
  }
  to {
    left: 0;
    width: 1194px;
    opacity: 1;
  }
}

@keyframes bgslide {
  from {
    left: -100%;
    width: 0;
    opacity: 0;
  }
  to {
    left: 0;
    width: 1194px;
    opacity: 1;
  }
}


.login {
  max-height: 100vh;
  overflow-Y: auto;
  float: left;
  margin: 0 auto;
  width: calc(100% - 1194px);
}

.login .container {
  width: 505px;
  margin: 0 auto;
  position: relative;
}

.login .container h1 {
  margin-top: 100px;
  font-size: 35px;
  font-weight: bolder;
}

.login .container .login-form {
  margin-top: 80px;
}

.login .container .login-form form {
  display: -ms-grid;
  display: grid;
}

.input {
  font-size: 16px;
  font-family: inherit;
  font-weight: normal;
  background: rgba(57, 57, 57, 0.07);
  margin: 12.5px 0;
  height: 68px;
  border: none;
  padding: 0 30px;
  border-radius: 10px;
}

.button[type=submit] {
  background: -webkit-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
  background: -o-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
  background: linear-gradient(-20deg, #f794a4 0%, #fdd6bd 100%);
  border: none;
  margin-top: 124px;
  margin-bottom: 20px;
  width: 241px;
  height: 58px;
  text-transform: uppercase;
  color: white;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  font-weight: bold;
  font-size: 20px;
}

.button[type=submit]:hover::after {
  opacity: 1;
}

.button[type=submit]::after {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 10px;
  opacity: 0;
  top: 0;
  left: 0;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  right: 0;
  bottom: 0;
  background: -webkit-gradient(linear, left bottom, left top, from(#09203f), to(#537895));
  background: -webkit-linear-gradient(bottom, #09203f 0%, #537895 100%);
  background: -o-linear-gradient(bottom, #09203f 0%, #537895 100%);
  background: linear-gradient(to top, #09203f 0%, #537895 100%);
}

.info{
  font-style: italic;
  margin: 2rem 0 2rem 0;
}


@media only screen and (min-width: 1024px) and (max-width: 1680px) {
  .bg-illustration {
    width: 50%;
    -webkit-animation: none;
            animation: none;
  }

  .login {
    width: 50%;
  }
}
/* Display 12", iPad PRO Portrait, iPad landscape */
@media only screen and (max-width: 1024px) {
  body {
    overflow-x: hidden;
  }

  @-webkit-keyframes slideIn {
    from {
      left: -100%;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }

  @keyframes slideIn {
    from {
      left: -100%;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }
  .bg-illustration {
    float: none;
    background: transparent center center;
    background-size: cover;
    -webkit-animation: slideIn 0.8s ease-in-out forwards;
            animation: slideIn 0.8s ease-in-out forwards;
    width: 100%;
    height: 190px;
    text-align: center;
  }
  .bg-illustration img {
    width: 100px;
    height: auto;
    margin: 20px auto !important;
    text-align: center;
  }
  .bg-illustration .burger-btn {
    left: 33px;
    top: 29px;
    display: block;
    position: absolute;
  }
  .bg-illustration .burger-btn span {
    display: block;
    height: 4px;
    margin: 6px;
    background-color: #fff;
  }
  .bg-illustration .burger-btn span:nth-child(1) {
    width: 37px;
  }
  .bg-illustration .burger-btn span:nth-child(2) {
    width: 28px;
  }
  .bg-illustration .burger-btn span:nth-child(3) {
    width: 20px;
  }

  .login {
    float: none;
    margin: 0 auto;
    width: 100%;
  }
  .login .container {
    -webkit-animation: slideIn 0.8s ease-in-out forwards;
            animation: slideIn 0.8s ease-in-out forwards;
    width: 85%;
    float: none;
  }
  .login .container h1 {
    font-size: 25px;
    margin-top: 40px;
  }
  .login .container .login-form {
    margin-top: 90px;
  }
  .input {
    height: 45px;
  }
  .button[type=submit] {
    height: 45px;
    margin-top: 100px;
  }
}

.ab{ 
/*  display: flex;*/
/*  align-items: center;*/
  position: absolute;
  top: 225px;
  right: 0;
  padding-right: 5px;
}
.abb{ 
/*  display: flex;*/
/*  align-items: center;*/
  position: absolute;
  top: 315px;
  right: 0;
  padding-right: 5px;
}

.failure{
  font-style: italic;
  margin: 2rem 0 2rem 0;
  color: red;
}

.link{
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
  color: var(--color-5);
}

.link:visited {
  color: var(--color-7);
}

.link:hover {
  border-bottom: 1px solid;
  color: var(--color-2);
  background: var(--color-1);
  border-radius: .5em;
}
</style>
