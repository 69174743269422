<template>
  <div class="custom-alert" :class="messageType === 'success' ? 'alert-success' : 'alert-danger'" role="alert"
       v-if="message" :style="`position: ${position}; animation-delay: 0s, ${(duration/1000) - 0.5}s;`">
    {{ message }}
  </div>

</template>

<script>

export default {
  name: 'Alert',
  props: {
    message: String,
    messageType: {
      type: String,
      default: 'success'
    },
    duration: {
      type: Number,
      default: 2000
    },
    position: {
      type: String,
      default: 'relative'
    }
  },
  mounted () {
    setTimeout(() => {
      this.$emit('close')
    }, this.duration)
  }
}
</script>

<style scoped>
.custom-alert {
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 12;
  text-align: center !important;
  padding: 8px 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  line-height: 32px;
  letter-spacing: 0.25px;
  opacity: 0.95;
  animation-name: fade-in, fade-out;
  animation-duration: 0.5s, 0.5s;
}
.alert-danger {
  color: black !important;
}
.alert-success {
  background: #202D59;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
